import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import LandingPage from "./components/app";
import PrivacyPolicy from "./components/privacy/privacy_policy";
import { NotFoundScreen } from "./not_found";

const RoutesEntryPoint = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />

        <Route path="/privacy-policy" element={<PrivacyPolicy />} />

        <Route
          path="*"
          element={
            <NotFoundScreen
              title={"404 Error"}
              subtitle={"This page is not available."}
            />
          }
        />
      </Routes>
    </Router>
  );
};

export default RoutesEntryPoint;
