import React from "react";
import ReactDOM from "react-dom/client";
import "./css/index.css";
import RoutesEntryPoint from "./router";

const root = ReactDOM.createRoot(
  document.getElementById("root") 
);

root.render(<RoutesEntryPoint />);
